import React, { useEffect, useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { handleAlertMessage } from "../../../../helpers/Helpers";
import ApiClientService from "../../../../services/auth/api-client-service";
import SubQuestionSet from "./SubQuestionSet";
import { SurveyContext, useContext } from "../../../../context/context";
import { useHistory } from "react-router-dom";
import { QuestionType } from "../../../../enums/questionTypeEnum";
import Select from "react-select";
import ImportExcel from "../../../pages/ImportExcel";
import { SurveyType } from "../../../../enums/surveyTypeEnum";

const QuestionSetAdd = (props) => {
  const defaultSurveyTypeOptions = [
    {
      label: "Form",
      value: "Form",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  const history = useHistory();
  const {
    surveySection,
    setSurveySection,
    subQuestionSet,
    setSubQuestionSet,
    snackbar,
    setSnackbar,
  } = useContext(SurveyContext);
  const [surveyTypeOptions, setSurveyTypeOptions] = useState(
    defaultSurveyTypeOptions
  );
  const [isDisabled, setIsDisabled] = useState(false);

  
  const subQuestionSetAddClick = () => {
    var message = validationQuestionSetRules();
    if (message.trim() != "")
      return handleAlertMessage(snackbar, setSnackbar, message, "error");
    var newState = [...subQuestionSet];

    var defaultSubQuestionSet = {
      name: "",
      surveyQuestions: [
        {
          questionText: "",
          isRequired: false,
          questionType: {},
          freeText: "",
          keyboardType: {
            value: 1,
            label: "Alfanumeric",
          },
          surveyAnswers: [],
        },
      ],
    };
    newState.push(defaultSubQuestionSet);
    setSubQuestionSet(newState);
  };
  function validationQuestionSetRules() {
    var message = "";
    if (surveySection.name == null || surveySection.name.trim() == "")
      message = "Question Name";
    else if(surveySection.surveyType == null) message = "Survey Type";
    else return message;
    return message + " is Empty";
  }

  const postAction = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    var surveyQuestionSections = [];
    subQuestionSet.forEach((sqSet, i) => {
      var surveyQuestions = [];
      surveyQuestionSections.push({
        Title: sqSet.name,
        DisplayOrder: sqSet.displayOrder,
        SurveyQuestions: [],
      });
      sqSet.surveyQuestions.forEach((sQuestion, j) => {
        var questionTypeValue = sQuestion.questionType.value;
        surveyQuestions.push({
          QuestionText: sQuestion.questionText,
          SurveyQuestionTypeId: sQuestion.questionType.value,
          SurveyAnswers: [],
          IsMandatoryQuestion: sQuestion.isRequired,
          DisplayOrder: sQuestion.displayOrder,
          FreeText:
            QuestionType.Free_Text == questionTypeValue ||
            QuestionType.Form == questionTypeValue
              ? sQuestion.freeText
              : "",
          KeyboardTypeId: sQuestion.keyboardType?.value || null,
          DefaultAnswer: sQuestion.defaultAnswer,
        });

        var surveyAnswers = [];
        sQuestion.surveyAnswers.forEach((answer) => {
          surveyAnswers.push({
            AnswerText: answer.answerText,
          });
        });
        surveyQuestions[j].SurveyAnswers = surveyAnswers;
      });
      surveyQuestionSections[i].SurveyQuestions = surveyQuestions;
    });

    var data = {
      Id: surveySection.id,
      Title: surveySection.name,
      Type: surveySection.surveyType?.value,
      Description: surveySection.message,
      surveyQuestionSections: surveyQuestionSections,
    };

    ApiClientService.post(process.env.REACT_APP_API_SAVE_SURVEY, data).then(
      (value) => {
        if (value?.status == 200 || value?.status == 201 || value?.status == 204) {
          var message = "Update process successful.";
          handleAlertMessage(snackbar, setSnackbar, message, "success");
          setTimeout(() => history.push("/survey"), 2000);
        } else {
          var message = "An error occurred";
          handleAlertMessage(snackbar, setSnackbar, message, "error");
        }
      }
    );
  };
  return (
    <>
      <form onSubmit={postAction.bind(this)} autoComplete="off">
        <Row className="mt-3">
          <Col xs={12} sm={4}>
            <label>Question Set Name</label>
            <Input
              required
              onChange={(e) =>
                setSurveySection({ ...surveySection, name: e.target.value })
              }
              type="text"
              name="text"
              value={surveySection.name}
            />
          </Col>
          <Col sm={4}>
            <label>Message</label>
            <Input
              required
              onChange={(e) =>
                setSurveySection({ ...surveySection, message: e.target.value })
              }
              type="text"
              name="Message"
              value={surveySection.message}
            />
          </Col>
          <Col sm={4}>
            <label>Survey Type</label>
            <Select
              required
              value={surveySection.surveyType}
              options={surveyTypeOptions}
              isSearchable={false}
              onChange={(e) =>
                setSurveySection({ ...surveySection, surveyType: e })
              }
            />
          </Col>
          <Col
            sm={6}
            className="mt-4 d-flex justify-content-start align-items-center"
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isDisabled}
            >
              Save
            </button>
          </Col>
          <Col
            sm={6}
            className="mt-4 d-flex justify-content-end align-items-center"
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={(e) => subQuestionSetAddClick(e)}
            >
              Sub Question Set Add
            </button>
          </Col>
          {surveySection.surveyType?.label === SurveyType.Other ? (
            <Col sm={12} className="mt-4 ">
              <ImportExcel
                subQuestionSet={subQuestionSet}
                setSubQuestionSet={setSubQuestionSet}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </form>
      <SubQuestionSet />
    </>
  );
};

export default QuestionSetAdd;
