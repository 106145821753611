import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import { FaPen } from "react-icons/fa";
import { Table, Tag } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import "../../pages/table.css";
import { FilterContext } from "../../../context/FilterContext";
import { tableFilter, timeDiff } from "../../../helpers/Helpers";
import { formatDecimal } from "../../../helpers/ConverterHelpers";

function UnapprovedActivities(props) {
  const defaultFilters = [
    {
      DraftType: [],
      ProjectName: [],
      MainWork: [],
      DocNum: [],
      RouteStage: [],
      Employee: [],
      Segment: [],
      Task: [],
      IssueID: [],
      Date: [],
      StartHour: [],
      EndHour: [],
      Type: [],
      Resource: [],
      ConsumptionAmount: [],
      ConsumtionUnit: [],
      SerialNumber: [],
      Actions: [],
    },
  ];

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);

  const [search, setSearch] = useState();

  const contextData = {
    filters,
    setFilters,
    search,
    setSearch,
  };
  const enableLoading = () => {
    setLoading(false);
  };

  function getData() {
    setRows([]);
    setCount(0);
    setPage(0);
    var arrayRows = [];
    var promises = [];
    ApiClientService.get(
      process.env.REACT_APP_API_GET_UNAPPROVE_WAITING_ACTIVITIES,
      null
    ).then((value) => {
      if (!value) return;

      var issueList = value.data.data;

      if (issueList != null) {
        issueList.forEach(function(issue) {
          let employeeName = "";
          let params = {
            userId: issue.userId,
          };

          promises.push(
            ApiClientService.get(
              process.env.REACT_APP_API_GET_USER_BY_ID,
              params
            ).then((value) => {
              var employee = value.data.data;
              let actionNum = 0;
              if (employee != null) {
                employeeName =
                  employee.firstName !== null
                    ? employee.lastName !== null
                      ? employee.firstName + " " + employee.lastName
                      : employee.userName !== null
                      ? employee.userName
                      : ""
                    : "";
              }
              issue.employeeName = employeeName;
              var itemsLentgh = 0;
              var resourceLength = 0;

              if (issue.issueItems.length > 0) {
                actionNum = 1;
                itemsLentgh = issue.issueItems.length;
              }
              if (issue.issueResources.length > 0) {
                resourceLength = issue.issueResources.length;
                actionNum = 2;
              }
              var isBtnFlag = false;
              arrayRows.push(
                createModel(issue, null, null, "issue", actionNum, true)
              );
              issue.issueItems.forEach(function(issueItem, index) {
                isBtnFlag = false;
                if (itemsLentgh - 1 == index) isBtnFlag = true;
                arrayRows.push(
                  createModel(
                    issue,
                    issueItem,
                    null,
                    "issueItem",
                    actionNum,
                    isBtnFlag
                  )
                );
              });
              issue.issueResources.forEach(function(issueResource, index) {
                isBtnFlag = false;
                if (resourceLength - 1 == index) isBtnFlag = true;

                arrayRows.push(
                  createModel(
                    issue,
                    null,
                    issueResource,
                    "issueResource",
                    actionNum,
                    isBtnFlag
                  )
                );
              });
            })
          );
        });
        Promise.all(promises).then(() => {
          setCount(arrayRows.length);
          arrayRows.forEach((row) => {
            setRows((rows) =>
              [...rows, row].sort((a, b) =>
                a.employeStartTime < b.employeStartTime ? -1 : 1
              )
            );
          });
          createHeaders();
          if (arrayRows.length > 0) createFilterData(arrayRows);
          enableLoading();
        });
      }
    });
  }
  const createHeaders = () => {
    var headers = [];
    var object = defaultFilters[0];
    Object.keys(object).forEach((key) => {
      if (
        key == "ProjectName" ||
        key == "MainWork" ||
        key == "DocNum" ||
        key == "RouteStage" ||
        key == "Employee" ||
        key == "Segment" ||
        key == "Task" ||
        key == "IssueID" ||
        key == "Date"
      ) {
        headers.push({
          title: key == "Segment" ? "Working Location" : key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
        });
      } else if (key == "DraftType") {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
          render: (_, { DraftType }) => createMainDraftType(DraftType),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };
  function createMainDraftType(draftType) {
    var color = "";
    if (draftType) {
      if (draftType == "Draft") color = "volcano";
      else color = "green";
      return (
        <Tag color={color} key={draftType}>
          {draftType.toUpperCase()}
        </Tag>
      );
    }
  }
  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    var array = defaultFilters[0];
    var object = defaultFilters[0];

    Object.keys(object).forEach((key) => {
      rows.forEach((element) => {
        if (element[key] && !object[key].some((x) => x.value == element[key])) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  function createMainActionButtons(issue) {
    return (
      <div className="row" key={issue.id}>
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to={{
              pathname: `/activity-add-edit`,
              state: {
                params: {
                  docStgId: issue.docStgId, //1
                  issueId: issue.id,
                  pEntry: issue.projectEntry,
                  docEntry: issue.docEntry,
                  stgEntry: issue.stgEntry,
                  projectName: issue.projectName,
                  docNum: issue.docNum,
                  stgDesc: issue.stgDesc,
                  userId: issue.userId,
                  stageName: issue.stageName,
                  workingLocation: issue.workingLocation,
                  subWorkingLocation: issue.subWorkingLocation,
                  problemLocation: issue.problemLocation,
                  treatmentPoint: issue.treatmentPoint,
                  action: "update",
                },
              },
            }}
            className="btn btn-warning mr-2"
          >
            <FaPen />
          </Link>
        </div>
      </div>
    );
  }

  function createModel(issue, issueItem, issueResource, type, actionNum, flag) {
    let source;
    switch (type) {
      case "issue":
        source = {
          DraftType: issue.isDraft ? "Draft" : "Issue",
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issue.startDate).format("DD/MM/YYYY"),
          StartHour: moment(issue.startDate).format("HH:mm"),
          EndHour: issue.endDate ? moment(issue.endDate).format("HH:mm") : "",
          Type: "Labor",
          Resource: "Labor",
          ConsumptionAmount: issue.endDate
            ? timeDiff(issue.startDate, issue.endDate)
            : "",
          ConsumtionUnit: "hour",
          SerialNumber: "-",
          Actions: flag && actionNum == 0 ? createMainActionButtons(issue) : "",
        };
        break;

      case "issueResource":
        source = {
          DraftType: "",
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issueResource.startDate).format("DD/MM/YYYY"),
          StartHour: moment(issueResource.startDate).format("HH:mm"),
          EndHour: moment(issueResource.endDate).format("HH:mm"),
          Type: "Equipment",
          Resource: issueResource.resourceName,
          ConsumptionAmount:
            parseInt(
              moment
                .duration(
                  moment(issueResource.endDate).diff(issueResource.startDate)
                )
                .asHours()
            ) +
            "H " +
            (parseInt(
              moment
                .duration(
                  moment(issueResource.endDate).diff(issueResource.startDate)
                )
                .asMinutes()
            ) %
              60) +
            "M",
          ConsumtionUnit: "hour",
          SerialNumber: issueResource.resourceSerial,
          Actions: flag && actionNum == 2 ? createMainActionButtons(issue) : "",
        };
        break;

      case "issueItem":
        source = {
          DraftType: "",
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issue.startDate).format("DD/MM/YYYY"),
          StartHour: "-",
          EndHour: "-",
          Type: "Materials",
          Resource: issueItem.itemName,
          ConsumptionAmount: formatDecimal(issueItem.quantity),
          ConsumtionUnit: issueItem.itemInventoryUoM,
          SerialNumber: "-",
          Actions: flag && actionNum == 1 ? createMainActionButtons(issue) : "",
        };
        break;

      case "mainAction":
        source = {
          DraftType: "",
          ProjectName: "",
          MainWork: "",
          DocNum: "",
          RouteStage: "",
          Employee: "",
          Segment: "",
          Task: "",
          IssueID: "",
          Date: "",
          StartHour: "",
          EndHour: "",
          Type: "",
          Resource: "",
          ConsumptionAmount: "",
          ConsumtionUnit: "",
          SerialNumber: "",
          Actions: "",
        };
        break;

      default:
        break;
    }
    return source;
  }
  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <FilterContext.Provider value={contextData}>
      <div className="assign-activities row mt-5">
        <div className="col-12">
          <Table
            columns={columns}
            dataSource={rows}
            onChange={onChange}
            scroll={{ x: 3000 }}
            loading={loading}
          />
        </div>
      </div>
    </FilterContext.Provider>
  );
}

export default UnapprovedActivities;
