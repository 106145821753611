/**
 * Converts a string value to its boolean equivalent.
 *
 * @param {string} stringValue - The string to convert to a boolean.
 * @returns {boolean} The boolean value corresponding to the string input.
 */
export function StringToBoolean(stringValue) {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return false;
  }
}


/**
 * Formats a given number to a decimal with exactly three decimal places.
 *
 * @param {number} number - The number to format.
 * @returns {string} The formatted number as a string with three decimal places.
 */
export const formatDecimal = (number) => {
  return number.toFixed(3);
}