import ApiClientService from "../services/auth/api-client-service";
import ProjectExceptionService from "../services/projectException/projectExceptionService";
import { distinctArray, dynamicSort } from "./Helpers";

export function GetUserSelectList(setUsers) {
  ApiClientService.get(
    process.env.REACT_APP_API_GET_USER_SELECT_VIEW,
    null
  ).then((value) => {
    if (!value) return;
    var array = value.data;
    array.sort(dynamicSort("label"));
    setUsers(array);
  });
}
export function GetProjectSelectList(setProjects) {
  ApiClientService.get(
    process.env.REACT_APP_API_GET_PROJECT_SELECT_VIEW,
    null
  ).then((value) => {
    if (!value) return;
    var array = value.data;
    array.sort(dynamicSort("label"));
    setProjects(array);
  });
}
export function GetItemSetList(setItemSet) {
  ApiClientService.get(process.env.REACT_APP_API_GET_ITEMSET, null).then(
    (value) => {
      if (!value) return;
      const dataItemSet = [];
      const array = value.data.data;
      array.sort(dynamicSort("name"));
      array.map((item) =>
        dataItemSet.push({
          itemSetId: item.id,
          label: item.name,
          value: item.id,
        })
      );
      dataItemSet.sort(dynamicSort("label"));
      setItemSet(dataItemSet);
    }
  );
}
export function GetItemList(setItemList) {
  ApiClientService.get(process.env.REACT_APP_API_GET_ITEMSET, null).then(
    (value) => {
      if (!value) return;
      const dataItem = [];
      const array = value.data.data;
      array.forEach((data) => {
        data.itemBoms.forEach((itemBom) => {
          dataItem.push({
            itemId: itemBom.itemId,
            label: itemBom.item.name,
            value: itemBom.item.id,
          });
        });
      });
      dataItem.sort(dynamicSort("label"));
      setItemList(dataItem);
    }
  );
}
export function GetProjectItemSelectList(setProjectItems) {
  ApiClientService.get(process.env.REACT_APP_API_PROJECT_ITEM, null).then(
    (value) => {
      if (!value) return;
      var array = value.data.data;
      var projectItems = [];
      array.forEach((project) => {
        project.stages.forEach((stage) => {
          stage.docs.forEach((doc) => {
            doc.docStgs.forEach((docStg) => {
              docStg.docStgItems.forEach((docStgItem) => {
                projectItems.push({
                  projectId: project.id,
                  label: docStgItem.item.name,
                  value: docStgItem.id,
                });
              });
            });
          });
        });
      });
      projectItems.sort(dynamicSort("label"));
      setProjectItems(projectItems);
    }
  );
}
export function GetProjectStgSelectList(
  setProjectOptions,
  setProjectStgList,
  setStgOptions
) {
  var projectList = [];
  var stgList = [];
  ApiClientService.get(process.env.REACT_APP_API_PROJECT_STG, null).then(
    (value) => {
      if (!value) return;
      var array = value.data.data;
      array.forEach((project) => {
        projectList.push({
          label: project.name,
          value: project.id,
        });
        project.stages.forEach((stage) => {
          stage.docs.forEach((doc) => {
            doc.docStgs.forEach((docStg) => {
              stgList.push({
                projectId: project.id,
                label: docStg.stg.code,
                value: docStg.stg.id,
              });
            });
          });
        });
      });
      projectList.sort(dynamicSort("label"));
      stgList.sort(dynamicSort("label"));
      setProjectOptions(projectList);
      setProjectStgList(stgList);
      setStgOptions(distinctArray(stgList, "label"));
    }
  );
}

export function GetProjectStageStgSelectList(
  setProjectOptions,
  setStgOptions,
  setStageList,
  setStgList
) {
  var projectList = [];
  var stgList = [];
  var stageList = [];

  ApiClientService.get(process.env.REACT_APP_API_PROJECT_STG, null).then(
    (value) => {
      if (!value) return;
      var array = value.data.data;
      array.forEach((project) => {
        projectList.push({
          label: project.name,
          value: project.id,
        });
        project.stages.forEach((stage) => {
          stage.docs.forEach((doc) => {
            stageList.push({
              projectId: project.id,
              label: stage.name,
              value: stage.id,
            });
            doc.docStgs.forEach((docStg) => {
              stgList.push({
                stageId: stage.id,
                label: docStg.stg.code,
                value: docStg.stg.id,
              });
            });
          });
        });
      });
      projectList.sort(dynamicSort("label"));
      setProjectOptions(projectList);

      stageList.sort(dynamicSort("label"));
      setStageList(distinctArray(stageList, "label"));

      stgList.sort(dynamicSort("label"));
      setStgList(stgList);
      setStgOptions(distinctArray(stgList, "label"));
    }
  );
}

export function GetSurveySelectList(setSurveys) {
  ApiClientService.get(process.env.REACT_APP_API_GET_SURVEY_SELECT, null).then(
    (value) => {
      if (!value) return;
      var array = value.data;
      array.sort(dynamicSort("label"));
      setSurveys(array);
    }
  );
}
export function GetStgSelectList(setStgs) {
  ApiClientService.get(process.env.REACT_APP_API_GET_STG_SELECT, null).then(
    (value) => {
      if (!value) return;
      var array = value.data;
      array.sort(dynamicSort("label"));
      setStgs(array);
    }
  );
}
export function GetSurveyDisplayStepList(setDisplayStepOptions) {
  ApiClientService.get(
    process.env.REACT_APP_API_GET_ALL_SURVEY_DISPLAY_STEP,
    null
  ).then((value) => {
    if (!value.data) return console.log("Bir hata oluştu.");
    var displaySteps = value.data.data?.map((x) => ({
      label: x.name,
      value: x.id,
    }));
    setDisplayStepOptions(displaySteps);
  });
}
export function GetKeyboardTypeSelectList(setKeyboardTypeOptions) {
  ApiClientService.get(
    process.env.REACT_APP_API_GET_KEYBOARD_SELECT,
    null
  ).then((value) => {
    if (!value?.data) return console.log("Bir hata oluştu.");
    setKeyboardTypeOptions(value.data ?? []);
  });
}
export function GetResourceShortNameSelectList(setShortNameOptions, id) {
  var request = {
    docstgId: id,
  };
  ApiClientService.get(
    process.env.REACT_APP_API_GET_ALL_REOURCES_SHORT_NAME,
    request
  ).then((response) => {
    if (!response) return;
    if (response.data.data) {
      var res = response.data.data;
      var shortNames = [];
      res.forEach((element) => {
        shortNames.push({
          value: element.id,
          label: element.name.trim(),
        });
      });
      shortNames.sort(dynamicSort("label"));
      setShortNameOptions(shortNames);
    }
  });
}

export function GetItemSelectList(setItemOptions, docStgId, exception) {
  var request = {
    docstgId: docStgId,
  };

  ApiClientService.get(process.env.REACT_APP_API_GET_ALL_ITEMS, request).then(
    async (value) => {
      if (!value) return;
      var data = value.data.data;
      var arrayMaterial = [];
      data.forEach((element) => {
        arrayMaterial.push({
          id: element.id,
          value: element.code,
          label: element.name == null ? element.code : element.name,
          uom: element.uom ?? element.inventoryUom,
          batchNumber: element.code,
          itemType: "I",
        });
      });
      const newItems = await ExceptionFilteredItems(arrayMaterial, exception);
      setItemOptions(newItems);
    }
  );
}

export async function ExceptionFilteredItems(itemOptions, body) {
  let newItems = [];
  const exceptions = await ProjectExceptionService.exceptionFilteredItems(body);
  if (exceptions.status === 200) {
    var data = [];
    var itemCodes = data.map((x) => x.itemCode);
    newItems = itemOptions.filter((x) => !itemCodes.includes(x.value));
    newItems.sort(dynamicSort("label"));
    newItems.unshift({
      value: 0,
      label: "None",
    });
  }
  return newItems;
}
export function GetWhsBatchNumberSelectList(setBatchNumbers, docStgId) {
  var request = {
    docstgId: docStgId,
  };

  ApiClientService.get(
    process.env.REACT_APP_API_GET_WHS_ITEMS_DOC_STG_ID,
    request
  ).then((value) => {
    if (!value) return;
    var data = value.data.data;
    var array = [];
    data.forEach((element) => {
      array.push({
        itemId: element.itemId,
        value: element.batchNumber,
        label: element.batchNumber,
      });
    });
    array.sort(dynamicSort("label"));
    setBatchNumbers(array);
  });
}
