import ApiClientService from "../services/auth/api-client-service";
import moment from "moment";
import { Tag } from "antd";
import React from "react";
import { formatDecimal } from "./ConverterHelpers";

const _ColorList = ["green", "geekblue", "volcano"];

export function formatAMPM(a) {
  const date = new Date(a);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, "0");
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function timeDiff(startTime, endTime) {
  var time =
    parseInt(moment.duration(moment(endTime).diff(startTime)).asHours()) +
    "H " +
    (parseInt(moment.duration(moment(endTime).diff(startTime)).asMinutes()) %
      60) +
    "M";
  time.replace("-", "");
  return time;
}

export function tableFilter(key, value) {
  if (key !== null) return key.toString().includes(value);
  return false;
}
export function getAllImageTag(setData) {
  ApiClientService.get(process.env.REACT_APP_API_IMAGE_TAG_GET_ALL, null).then(
    (value) => {
      if (!value) return;
      var data = [];
      value.data.data.forEach((element) => {
        data.push({
          label: element.tag,
          value: element.id,
        });
      });
      setData(data);
    }
  );
}
export function nigthShiftValide(data) {
  if (data === undefined || data == null || !Array.isArray(data)) return false;

  var isNotNightShift = data.filter((x) => x.IsNightShift == false);
  if (isNotNightShift.length > 0) return false;

  const arrDates = data.map((str) => onlyDateFormat(str.Date));
  if (minMaxDayDiff(arrDates) > 1) return false;

  return true;
}
export function getArraySelector(array, key) {
  var data = [];
  array.forEach((element) => {
    data.push(element);
  });
  return data;
}
export function dateFormat(date, time) {
  if (date == "" || time == "") return null;
  var splitDate = date.split("-");

  var month = "" + splitDate[1];
  var day = "" + splitDate[2];
  var year = splitDate[0];

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  var dateTime = [year, month, day].join("-") + "T" + time;
  var timestamp = Date.parse(dateTime);
  return !isNaN(timestamp) ? dateTime : null;
}
export function onlyDateFormat(date) {
  const [month, day, year] = date.split("/");
  return [year, month, day].join("-");
}
export function minMaxDayDiff(arrDates) {
  const min = Math.min(...arrDates);
  const max = Math.max(...arrDates);

  const diffTime = Math.abs(max - min);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return parseInt(diffDays);
}
export function DateDiff(minDate, maxDate) {
  const diffTime = Math.abs(maxDate - minDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return parseInt(diffDays);
}
export function isDistinctArray(array, key) {
  const data = [...new Map(array.map((item) => [item[key], item])).values()];

  return data.length > 1 ? true : false;
}
export function distinctArray(array, key) {
  //Keye özgü tekilleme
  const data = [...new Map(array.map((item) => [item[key], item])).values()];

  return data;
}
export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
export function checkIfDateBetween(date, start, end) {
  if (date >= start && date <= end) return true;
  return false;
}
export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
export function hourDiff(start, end) {
  return parseInt(moment.duration(moment(end).diff(start)).asHours());
}
export function minuteDiff(start, end) {
  return parseInt(moment.duration(moment(end).diff(start)).asMinutes()) % 60;
}

export function camelCase(str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}
export function createTableHeaders(setHeaders, data) {
  var headers = [];
  data.forEach((key) => {
    if (key === "tag") {
      headers.push({
        title: "tag",
        key: "tag",
        dataIndex: "tag",
        render: (_, { tag }) => createMainActionButtons(tag),
      });
    } else if (key === "stgCodes") {
      headers.push({
        title: key,
        key: key,
        dataIndex: key,
        render: (_, { stgCodes }) => (
          <>
            {stgCodes.map((stg) => {
              var color =
                _ColorList[Math.floor(Math.random() * _ColorList.length)];
              return (
                <Tag color={color} key={stg}>
                  {stg.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      })
    } else if (key === "date" || key === "startDate" || key === "endDate") {
      headers.push({
        title: key,
        key: key,
        dataIndex: [key],
        render: (date) => (
          <span>{moment(date).format("DD/MM/YYYY hh:mm")}</span>
        ),
      });
    } else if (key === "url") {
      headers.push({
        title: key,
        key: key,
        dataIndex: [key],
        render: (url) => (
          <a
            href={url}
            target="_blank"
          >
            {url !== null ? "Image Link" : ""}
          </a>
        ),
      });
    } else if (key !== "userId")
      headers.push({
        title: key,
        key: key,
        dataIndex: key,
        sorter: (a, b) => a[key].toString().localeCompare(b[key].toString()),
      });
  });
  setHeaders(headers);
}

function createMainActionButtons(tag) {
  var color = "";
  if (tag) {
    if (tag === "Before") color = "green";
    else if (tag === "During") color = "lime";
    else if (tag === "After") color = "magenta";
    else if (tag === "Finish") color = "volcano";
    return (
      <Tag color={color} key={tag}>
        {tag.toUpperCase()}
      </Tag>
    );
  }
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
export function updateStateArray(state, setState, index, value, param) {
  const newState = state.map((obj, i) => {
    if (index === i) {
      return { ...obj, [param]: value };
    }
    return obj;
  });

  setState(newState);
}
export function handleAlertMessage(snackbar, setSnackbar, message, type) {
  var newState = { ...snackbar };
  newState.open = true;
  newState.message = message;
  newState.variant = type;
  setSnackbar(newState);
}
export function downloadFile(fileName) {
  if (fileName.trim() != "") {
    var names = fileName.split(";");
    names.forEach(async (element) => {
      var src = element; //report page url ile birlikte 
      const image = await fetch(src);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement("a");
      link.href = imageURL;
      var a = imageBlog.type.split("/");
      link.download = element + "." + a[1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}

export function GetUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

/**
 * Combines two integer numbers represented as strings into a single decimal number.
 * 
 * This function takes two string representations of integers, converts them to integers,
 * concatenates them with a dot (.), and then parses the result as a float to form a decimal number.
 * If the second number is not an integer or cannot be converted to an integer, it defaults to 0.
 * 
 * @param {string} strNumber1 - The integer part of the decimal number as a string.
 * @param {string} strNumber2 - The fractional part of the decimal number as a string.
 * @returns {number} The combined decimal number.
 */
export function combineNumbersAsDecimal(strNumber1, strNumber2) {
  let number1 = parseFloat(strNumber1);

  if (isNaN(parseFloat(strNumber2))) {
    strNumber2 = "0";
  }

  let combinedString = number1.toString() + '.' + strNumber2;
  let combinedNumber = parseFloat(combinedString);

  return combinedNumber;
}

/**
 * Splits a decimal number into its integer (quantity) and fractional (decimal) parts.
 * 
 * This function takes a number or a string representing a decimal number,
 * converts it to a string (if necessary), checks if it contains a decimal point,
 * splits it at the decimal point if present, and returns an object with the integer part 
 * as `quantity` and the fractional part as `decimal`. If there is no fractional part, 
 * `decimal` will be an empty string.
 * 
 * @param {number|string} value - The decimal number to be split.
 * @returns {Object} An object containing the integer part as `quantity` and the fractional part as `decimal`.
 * @property {string} quantity - The integer part of the decimal number.
 * @property {string} decimal - The fractional part of the decimal number.
 */
export function splitNumber(value) {
  if (!value) {
    return { quantity: 0, decimal: 0 }
  }
  const valueString = formatDecimal(value);
  
  if (valueString.includes('.')) {
    const parts = valueString.split('.');
    const quantity = parts[0];
    const decimal = parts[1] || 0;
    return { quantity, decimal };
  } else {
    return { quantity: valueString, decimal: 0 };
  }
}