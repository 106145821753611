const SurveyAnswerReportType = {
  Answer: "answer",
  Date: "date",
  Project: "project",
  SubWorkingLocation: "subWorkingLocation",
  Stage: "stage",
  Stg: "stg",
  WorkingLocation: "workingLocation",
  QuestionSet: "questionSet",
  User: "user",
};

export { SurveyAnswerReportType };
