import React, { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../../../context/context";
import { Row, Col, Button } from "reactstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import Select from "react-select";
import ItemSetService from "../../../../services/item/itemSetService";
import { List, Typography } from "antd";
import { groupBy } from "../../../../helpers/Helpers";
import { itemSetStyles } from "../../PdfRender/style/style";
import { splitNumber } from "../../../../helpers/Helpers";

function ItemSetComponent({ issueSetItems }) {
  const {
    itemSets,
    setItemSets,
    action,
    itemOptions,
    batchNumbers,
    docStgId,
  } = useContext(ActivityContext);

  const [itemSetData, setItemSetData] = useState();
  const [fatherItemOptions, setFatherItemOption] = useState();
  const defaultItemSetRow = {
    selectedItem: {
      value: 0,
      label: "None",
    },
    childItems: {},
    itemType: "S",
  };

  useEffect(() => {
    ItemSetService.itemSetGetByDocStgId(docStgId)
      .then((response) => {
        if (response) {
          var data = response.data?.data;
          if (!data) return;
          setItemSetData(data);
          setFatherItemOption(
            data.map((x) => ({
              id: x.fatherItem.id,
              value: x.fatherItem.code,
              label: x.fatherItem.name,
              code: x.fatherItem.code,
              itemType: "S",
            }))
          );
          return response;
        } else {
          console.log("Servis hatası");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (issueSetItems && batchNumbers && action == "update") {
      var newIssueSetItems = [...issueSetItems];
      var groupedData = groupBy(
        newIssueSetItems.filter(
          (x) => x.itemType == "S" && x.fatherCode != null
        ),
        "fatherCode"
      );
      var fatherItems = [];
      for (const groupKey in groupedData) {
        const fatherItem = fatherItemOptions.find((x) => x.code == groupKey);

        fatherItems.push({
          selectedItem: fatherItem,
          childItems: groupedData[groupKey].map((x) => {
            const { quantity: childQuantity, decimal: childDecimal } = splitNumber(x.quantity);
            return {
            id: x.id,
            value: x.itemCode,
            label: x.itemName,
            code: x.itemCode,
            fatherCode: x.fatherCode,
            uom: x.itemInventoryUoM,
            quantity: childQuantity,
            decimal: childDecimal,
            itemType: "S",
            selectedBatchNumber: {
              itemId: itemOptions.find((i) => i.value == x.itemCode)?.id,
              label: x.batchNumber,
              value: x.batchNumber,
            },
            batchNumberOptions: batchNumbers?.filter(
              (b) =>
                b.itemId == itemOptions.find((i) => i.value == x?.itemCode)?.id
            ),
          }
          }),
          itemType: "S",
        });
      }
      setItemSets(fatherItems);
    }
  }, [issueSetItems, batchNumbers]);

  const handleChangeFatherItem = (fatherItem, index) => {
    let newState = [...itemSets];
    if (fatherItem) {
      newState[index]["selectedItem"] = fatherItem;
      var father = itemSetData?.find(
        (x) => x.fatherItem.code == fatherItem.code
      );
      let childItems = father?.childItems?.map((x) => {
        const { quantity: childQuantity, decimal: childDecimal } = splitNumber(x.quantity);
        return {
          id: x.id,
          value: x.code,
          label: x.name,
          code: x.code,
          fatherCode: fatherItem.code,
          uom: x.uom,
          quantity: childQuantity,
          itemType: "S",
          selectedBatchNumber: null,
          batchNumberOptions: batchNumbers?.filter((b) => b.itemId == x.id),
          decimal: childDecimal
        };
      });

      newState[index]["childItems"] = childItems;
    } else {
      newState[index] = { ...defaultItemSetRow };
    }

    setItemSets(newState);
  };

  const handleChangeBatchNumber = (selectedBatchNumber, index, cIndex) => {
    let newState = [...itemSets];
    newState[index]["childItems"][cIndex][
      "selectedBatchNumber"
    ] = selectedBatchNumber;
    setItemSets(newState);
  };
  const handleChangeOnlyIntegerNumber = (value, index, cIndex) => {
    let newState = [...itemSets];
    const filteredValue = value.replace(/[^0-9]/g, '');
    newState[index]["childItems"][cIndex]["quantity"] = filteredValue;
    setItemSets(newState);
  };
  const handleChangeOnlyDecimalNumber = (value, index, cIndex) => {
    let newState = [...itemSets];
    const filteredValue = value.replace(/[^0-9]/g, '').slice(0, 3);
    newState[index]["childItems"][cIndex]["decimal"] = filteredValue;
    setItemSets(newState);
  };

  const addRow = (event) => {
    var newState = [...itemSets];
    newState.push({ ...defaultItemSetRow });
    setItemSets(newState);
  };

  const removeRow = (index) => {
    var newState = [];
    var tempArray = [...itemSets];
    tempArray.forEach((element, i) => {
      if (index !== i) newState.push(element);
    });
    setItemSets(newState);
    console.log(newState);
  };

  return (
    <div>
      {itemSets?.map((itemSet, index) => (
        <>
          <Row key={"itemSet_index_row"} className={index > 0 ? "mt-3" : ""}>
            <Col xs={12} sm={6} md={4}>
              <label>Item Set</label>
              <Select
                value={itemSet.selectedItem}
                onChange={(e) => handleChangeFatherItem(e, index)}
                options={fatherItemOptions}
                key={"fatherDropdown_" + index}
                isClearable={true}
              />
            </Col>
            <Col
              sm={6}
              md={8}
              className="d-flex justify-content-end align-items-end"
            >
              {index === 0 ? (
                <Button color="primary" onClick={(e) => addRow(e)}>
                  <FaPlus />
                </Button>
              ) : (
                <Button color="danger" onClick={(e) => removeRow(index)}>
                  <FaTimes />
                </Button>
              )}
            </Col>
          </Row>
          {itemSet.childItems?.length > 0 ? (
            <List
              style={{ marginTop: 10 }}
              header={<div>Part 1 Of Set</div>}
              size="small"
              bordered
              dataSource={itemSet?.childItems}
              renderItem={(childItem, cIndex) => (
                <List.Item key={childItem.code + "_index" + cIndex}>
                  <List.Item.Meta
                    title={<Typography.Text>{childItem.label}</Typography.Text>}
                  />
                  <List.Item.Meta
                    style={{ marginRight: 10 }}
                    title={
                      <div>
                        <label>Batch Numbers</label>
                        <Select
                          value={childItem.selectedBatchNumber}
                          isClearable={true}
                          onChange={(e) =>
                            handleChangeBatchNumber(e, index, cIndex)
                          }
                          key={"childDropdown_" + index}
                          options={childItem.batchNumberOptions}
                        />
                      </div>
                    }
                  />
                  <List.Item.Meta
                    title={
                      <div>
                        <label>Quantity</label>
                      <div className="input-group">
                        
                        <input
                          key={`${index}_itemSet_uom_input`}
                          className="form-control"
                          id="inventoryUom"
                          placeholder="103"
                          value={childItem.quantity}
                          onChange={(e) =>
                            handleChangeOnlyIntegerNumber(
                              e.target.value,
                              index,
                              cIndex
                            )
                          }
                        />
                        <span style={itemSetStyles.decimalPoint}>.</span>
                        <input
                          key={`${index}_itemSet_uom_decimal_input`}
                          className="form-control"
                          id="itemSetDecimal"
                          placeholder="112"
                          value={childItem.decimal}
                          onChange={(e) =>
                            handleChangeOnlyDecimalNumber(
                              e.target.value,
                              index,
                              cIndex
                            )
                          }
                        />
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="itemSetValidationTooltipUsernamePrepend"
                          >
                            {!childItem.uom || childItem.uom == null || childItem.uom.trim() === ""
                              ? "number"
                              : childItem.uom}
                          </span>
                        </div>
                      </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <></>
          )}
        </>
      ))}
    </div>
  );
}

export default ItemSetComponent;
