import React from "react";
import { SurveyAnswerReportType } from "../../../../../enums/SurveyAnswerReportType";
import { TableColumnSort, TableAnwserMediaShow, TableDateFormat } from "../../../../../helpers/TableHelpers";
import { Table } from "antd";

const columns = [
    {
        title: SurveyAnswerReportType.Project,
        dataIndex: SurveyAnswerReportType.Project,
        key: SurveyAnswerReportType.Project,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.Project),
    },
    {
        title: SurveyAnswerReportType.Stage,
        dataIndex: SurveyAnswerReportType.Stage,
        key: SurveyAnswerReportType.Stage,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.Stage),
    },
    {
        title: SurveyAnswerReportType.Stg,
        dataIndex: SurveyAnswerReportType.Stg,
        key: SurveyAnswerReportType.Stg,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.Stg),
    },
    {
        title: SurveyAnswerReportType.WorkingLocation,
        key: SurveyAnswerReportType.WorkingLocation,
        dataIndex: SurveyAnswerReportType.WorkingLocation,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.WorkingLocation),
    },
    {
        title: SurveyAnswerReportType.SubWorkingLocation,
        dataIndex: SurveyAnswerReportType.SubWorkingLocation,
        key: SurveyAnswerReportType.SubWorkingLocation,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.SubWorkingLocation),
    },
    {
        title: SurveyAnswerReportType.QuestionSet,
        key: SurveyAnswerReportType.QuestionSet,
        dataIndex: SurveyAnswerReportType.QuestionSet,
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.QuestionSet),
    },
    {
        title: SurveyAnswerReportType.Answer,
        key: SurveyAnswerReportType.Answer,
        dataIndex: SurveyAnswerReportType.Answer,
        render: (_, record) => TableAnwserMediaShow(record?.answer),
        sorter: (a, b) => TableColumnSort(a, b, SurveyAnswerReportType.Answer),
    },
    {
        title: SurveyAnswerReportType.User,
        dataIndex: SurveyAnswerReportType.User,
        key: SurveyAnswerReportType.User,
    },
    {
        title: SurveyAnswerReportType.Date,
        key: SurveyAnswerReportType.Date,
        dataIndex: SurveyAnswerReportType.Date,
        render: (_, record) => TableDateFormat(record.date),
    },
];

export const SurveyAnswerTable = ({ rows, loading }) => {
    return <Table
        columns={columns}
        dataSource={rows}
        scroll={{ x: 3000 }}
        loading={loading}
    />
}