import React, { useEffect } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Row, Col, Input, Button } from "reactstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useState } from "react";
import ApiClientService from "../../../../services/auth/api-client-service";
import Select from "react-select";
import { SurveyContext, useContext } from "../../../../context/context";
import { QuestionType } from "../../../../enums/questionTypeEnum";
import { GetKeyboardTypeSelectList } from "../../../../helpers/SelectHelpers";
import { Form, Switch } from "antd";
import { memo } from "react";
import { SurveyType } from "../../../../enums/surveyTypeEnum";

function Question(props) {
  const { surveyQuestions, setIndex } = props;
  const { subQuestionSet, setSubQuestionSet, surveySection } = useContext(
    SurveyContext
  );

  const [questionTypeOptions, setQuestionTypeOptions] = useState([]);
  const [keyboardTypeOptions, setKeyboardTypeOptions] = useState([]);

  function getAllQuestionType() {
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ALL_SURVEY_QUESTION_TYPE,
      null
    ).then((value) => {
      if (!value?.data) return console.log("Bir hata oluştu.");
      var alfa = [];
      if (surveySection.surveyType?.value === SurveyType.Form) {
        var questionTypeFilter = value.data.data?.find(
          (x) => x.id === QuestionType.Form
        );
        alfa.push({
          label: questionTypeFilter.name,
          value: QuestionType.Form,
        });
      } else {
        alfa = value.data.data
          ?.filter((x) => x.id !== QuestionType.Form)
          .map((x) => ({
            label: x.name,
            value: x.id,
          }));
      }
      setQuestionTypeOptions(alfa);
    });
  }
  const handleChangeQuestionType = (value, index) => {
    var newState = [...surveyQuestions];
    var tempQuestion = { ...newState[index] };
    tempQuestion.questionType = value;
    tempQuestion.surveyAnswers = [];
    if (
      value.value == QuestionType.Multiple ||
      value.value == QuestionType.MoreThanOne
    ) {
      tempQuestion.surveyAnswers.push({
        answerText: "",
      });
      tempQuestion.defaultAnswer = null;
    } else if (value.value == QuestionType.Yes_No) {
      tempQuestion.defaultAnswer = "Yes";
    } else {
      tempQuestion.surveyAnswers = [];
      tempQuestion.defaultAnswer = null;
    }
    newState[index] = tempQuestion;
    var newSubQuestionData = [...subQuestionSet];
    newSubQuestionData[setIndex].surveyQuestions = newState;
    setSubQuestionSet(newSubQuestionData);
  };

  const handleChange = (value, param, qIndex) => {
    var newState = [...surveyQuestions];
    var tempQuestion = { ...newState[qIndex] };
    tempQuestion[param] = value;
    newState[qIndex] = tempQuestion;

    var newSubQuestionData = [...subQuestionSet];
    newSubQuestionData[setIndex].surveyQuestions = newState;
    setSubQuestionSet(newSubQuestionData);
  };

  const removeQuestion = (index) => {
    var data = [...surveyQuestions];
    var tempData = [];
    data.forEach((element, i) => {
      if (i != index) tempData.push(element);
    });
    var newState = [...subQuestionSet];
    newState[setIndex].surveyQuestions = tempData;
    setSubQuestionSet(newState);
  };

  const addAnswer = (index) => {
    var newState = [...surveyQuestions];
    var tempQuestion = { ...newState[index] };
    tempQuestion.surveyAnswers.push({
      answer: "",
    });
    newState[index] = tempQuestion;
    var newSubQuestionData = [...subQuestionSet];
    newSubQuestionData[setIndex].surveyQuestions = newState;
    setSubQuestionSet(newSubQuestionData);
  };
  const removeAnswer = (index, answerIndex) => {
    var newState = [...surveyQuestions];
    var tempQuestion = { ...newState[index] };
    var newArr = [];
    tempQuestion.surveyAnswers.forEach((element, i) => {
      if (answerIndex != i) newArr.push(element);
    });
    tempQuestion.surveyAnswers = newArr;
    newState[index] = tempQuestion;
    var newSubQuestionData = [...subQuestionSet];
    newSubQuestionData[setIndex].surveyQuestions = newState;
    setSubQuestionSet(newSubQuestionData);
  };

  const handleChangeAnswer = (index, answerIndex, value, param) => {
    var newState = [...surveyQuestions];
    var tempQuestion = { ...newState[index] };
    var answer = tempQuestion.surveyAnswers[answerIndex];
    answer[param] = value;
    //tempQuestion.answerIndex = answer;

    newState[index] = tempQuestion;
    var newSubQuestionData = [...subQuestionSet];
    newSubQuestionData[setIndex].surveyQuestions = newState;
    setSubQuestionSet(newSubQuestionData);
  };
  useEffect(() => {
    getAllQuestionType();
    GetKeyboardTypeSelectList(setKeyboardTypeOptions);
  }, []);

  return surveyQuestions?.map((question, qIndex) => (
    <div className="card p-5 mt-4 mb-4">
      <Row>
        <Col sm={12} className="mb-4">
          <label>Question</label>
          <Input
            value={question.questionText}
            name="questionText"
            onChange={(e) =>
              handleChange(e.target.value, "questionText", qIndex)
            }
            type="textarea"
            autoSize={{ minRows: 8, maxRows: 8 }}
          />
        </Col>
        <Col sm={6} md={4} lg={3}>
          <label>Question Type</label>
          <Select
            value={question.questionType}
            options={questionTypeOptions}
            isSearchable={false}
            onChange={(e) => handleChangeQuestionType(e, qIndex)}
          />
        </Col>
        {question.questionType.value == QuestionType.Free_Text ||
        question.questionType.value == QuestionType.Form ? (
          <>
            <Col sm={6} md={4} lg={3}>
              <label>Free Text</label>
              <Input
                onChange={(e) =>
                  handleChange(e.target.value, "freeText", qIndex)
                }
                type="text"
                name="Free Text"
                value={question.freeText}
              />
            </Col>
            <Col sm={6} md={4} lg={3}>
              <label>Keyboard Type</label>
              <Select
                value={question.keyboardType ?? keyboardTypeOptions[0]}
                options={keyboardTypeOptions}
                isSearchable={false}
                onChange={(e) => handleChange(e, "keyboardType", qIndex)}
              />
            </Col>
          </>
        ) : (
          <></>
        )}
        <Col sm={6} md={4} lg={3}>
          <label>Display Order</label>
          <Input
            onChange={(e) =>
              handleChange(e.target.value, "displayOrder", qIndex)
            }
            type="number"
            name="Display Order"
            value={question.displayOrder}
          />
        </Col>
        {question.questionType.value == QuestionType.Yes_No &&
        question.defaultAnswer != null ? (
          <Col sm={12} md={4} lg={2}>
            <label>Default Answer</label>
            <Form.Item>
              <Switch
                checkedChildren={question.defaultAnswer}
                unCheckedChildren={question.defaultAnswer}
                onChange={(e) =>
                  handleChange(e ? "Yes" : "No", "defaultAnswer", qIndex)
                }
                defaultChecked={question.defaultAnswer == "Yes" ? true : false}
              />
            </Form.Item>
          </Col>
        ) : (
          <></>
        )}
        <Col
          sm={6}
          md={
            question.questionType.value == QuestionType.Multiple ||
            question.questionType.value == QuestionType.MoreThanOne
              ? 4
              : question.questionType.value == QuestionType.Yes_No
              ? 3
              : 4
          }
          lg={
            question.questionType.value == QuestionType.Multiple ||
            question.questionType.value == QuestionType.MoreThanOne
              ? 3
              : question.questionType.value == QuestionType.Yes_No
              ? 2
              : 6
          }
          className={
            question.questionType.value == QuestionType.Multiple ||
            question.questionType.value == QuestionType.MoreThanOne
              ? "mt-4 d-flex align-items-start justify-content-start"
              : question.questionType.value == QuestionType.Yes_No
              ? "mt-4 d-flex align-items-start justify-content-start"
              : "mt-4 d-flex align-items-start justify-content-start"
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChange(e.target.checked, "isRequired", qIndex)
                }
                checked={question.isRequired}
                value={question.isRequired}
              />
            }
            label="Is Required"
          />
        </Col>
        <Col
          sm={6}
          md={
            question.questionType.value == QuestionType.Multiple ||
            question.questionType.value == QuestionType.MoreThanOne
              ? 4
              : question.questionType.value == QuestionType.Yes_No
              ? 3
              : 4
          }
          lg={
            question.questionType.value == QuestionType.Multiple
              ? 3
              : question.questionType.value == QuestionType.Yes_No
              ? 2
              : 6
          }
          className={
            question.questionType.value == QuestionType.Multiple ||
            question.questionType.value == QuestionType.MoreThanOne
              ? "mt-4 d-flex align-items-start justify-content-end"
              : question.questionType.value == QuestionType.Yes_No
              ? "mt-4 d-flex align-items-start justify-content-end"
              : "mt-4 d-flex align-items-start justify-content-end"
          }
        >
          <Button color="danger" onClick={() => removeQuestion(qIndex)}>
            <FaTimes />
          </Button>
        </Col>
      </Row>
      {(question.questionType.value == QuestionType.Multiple ||
        question.questionType.value == QuestionType.MoreThanOne) &&
      question.surveyAnswers?.length > 0 ? (
        <div
          class="row p-5 mt-5 mb-5"
          style={{ border: "1px solid #d1d1d1", borderRadius: "20px" }}
        >
          <div className="col-sm-12 mb-4 d-flex justify-content-end">
            <Button color="success" onClick={() => addAnswer(qIndex)}>
              Add Answer
            </Button>
          </div>
          {question.surveyAnswers?.map((answer, aIndex) => (
            <Col sm={12} lg={4}>
              <label>{"Answer " + (aIndex + 1)}</label>
              <div className="d-flex">
                <Input
                  value={answer.answerText}
                  type="text"
                  name="answer"
                  onChange={(e) =>
                    handleChangeAnswer(
                      qIndex,
                      aIndex,
                      e.target.value,
                      "answerText"
                    )
                  }
                />
                <Button
                  color="danger"
                  onClick={() => removeAnswer(qIndex, aIndex)}
                >
                  -
                </Button>
              </div>
            </Col>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  ));
}
export default memo(Question);
