import React from "react";
import moment from "moment";

export const TableAnwserMediaShow = (questionType, answer) => {
    if (questionType != null && (questionType == "Photo" || questionType == "Video")) {
        return <a
            href={`${process.env.REACT_APP_API_DOWNLOAD_IMAGE_URL}${answer}`}
            target="_blank"
        >
            {answer !== null ? "Image Link" : ""}
        </a>
    }
    return <span>{answer}</span>
}

//
export const TableDateFormat = (date) => {
    return <span>{moment(date).format("DD/MM/YYYY hh:mm")}</span>
}

export const TableColumnSort = (a, b, key) => {
    return a[key].toString().localeCompare(b[key].toString());
}