import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import { FaCheck, FaPen, FaIndent } from "react-icons/fa";
import UserDialog from "../../pages/Dialog";
import UserSnackbars from "../../pages/Snackbar";
import { Table } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import "../../pages/table.css";
import { FilterContext } from "../../../context/FilterContext";
import { tableFilter, timeDiff } from "../../../helpers/Helpers";
import IssueService from "../../../services/issue/issueService";
import { formatDecimal } from "../../../helpers/ConverterHelpers";

function ApproveWaitingActivities(props) {
  const timeout = 5000;
  const defaultFilters = [
    {
      ProjectName: [],
      MainWork: [],
      DocNum: [],
      RouteStage: [],
      Employee: [],
      Segment: [],
      Task: [],
      IssueID: [],
      Date: [],
      StartHour: [],
      EndHour: [],
      Type: [],
      Resource: [],
      ConsumptionAmount: [],
      ConsumtionUnit: [],
      SerialNumber: [],
      Actions: [],
    },
  ];
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState(
    "Do you approve the issues confirmation?"
  );
  const [popupBody, setPopupBody] = useState(
    "Are you sure you want to change the status of the relevant issues to approved?"
  );
  const [currentIssueId, setCurrentIssueId] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [variantSnackbar, setVariantSnackbar] = useState("");
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);

  const [search, setSearch] = useState();

  const contextData = {
    filters,
    setFilters,
    search,
    setSearch,
  };
  const enableLoading = () => {
    setLoading(false);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const setTotalCount = (count) => {
    setCount(count);
  };

  const changePage = (page) => {
    setPage(page);
  };
  const setrowsPerPageNumber = (page) => {
    setrowsPerPage(page);
  };
  function createActionButtons(id) {
    return (
      <Link
        to={{
          pathname: `/activity-add-edit`,
          state: {
            params: [
              {
                issueId: id, //1
                action: "update",
              },
            ],
          },
        }}
        className="btn btn-info"
      >
        <FaIndent />
      </Link>
    );
  }
  const openDialog = (id) => {
    setCurrentIssueId(id);
    setOpenPopup(true);
  };

  function approveAction(button) {
    ApiClientService.postSingle(
      process.env.REACT_APP_API_APPROVE_ISSUE,
      currentIssueId
    ).then((value) => {
      if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        setMessageSnackbar("Issue approving successful.");
        setVariantSnackbar("success");
        setOpenSnackbar(true);
        setLoading(true);
        setFilters(defaultFilters);
        getData();
      } else {
        setMessageSnackbar("An error occurred.");
        setVariantSnackbar("error");
        setOpenSnackbar(true);
      }
    });
    setOpenPopup(false);
  }
  function rejectAction() {
    setOpenPopup(false);
  }
  async function getData() {
    setRows([]);
    setCount(0);
    setPage(0);

    setLoading(true);

    try {
      const response = await IssueService.approveWaitingGetAll();
      if (response?.data?.data) {
        const issueList = response.data.data;
        const newArrayRows = [];
        issueList.forEach((issue) => {
          let actionNum = 0;
          let itemsLength = 0;
          let resourceLength = 0;
          
          if (issue.issueItems.length > 0) {
            actionNum = 1;
            itemsLength = issue.issueItems.length;
          }

          if (issue.issueResources.length > 0) {
            resourceLength = issue.issueResources.length;
            actionNum = 2;
          }
        
          let isBtnFlag = false;
          newArrayRows.push(
            createModel(issue, null, null, "issue", actionNum, true)
          );

          const handleIssueDetails = (details, type) => {
            details.forEach((detail, index) => {
              isBtnFlag = false;
              if (details.length - 1 === index) isBtnFlag = true;

              newArrayRows.push(
                createModel(
                  issue,
                  type === "issueItem" ? detail : null,
                  type === "issueResource" ? detail : null,
                  type,
                  actionNum,
                  isBtnFlag
                )
              );
            });
          };
          handleIssueDetails(issue.issueItems, "issueItem");
          handleIssueDetails(issue.issueResources, "issueResource");
        });
        setCount(newArrayRows.length);
        setRows((rows) =>
          [...rows, ...newArrayRows].sort((a, b) =>
            a.employeStartTime < b.employeStartTime ? -1 : 1
          )
        );
        createHeaders();

        if (newArrayRows.length > 0) createFilterData(newArrayRows);
      
        enableLoading();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  const createHeaders = () => {
    var headers = [];
    var object = defaultFilters[0];
    Object.keys(object).forEach((key) => {
      if (
        key == "ProjectName" ||
        key == "MainWork" ||
        key == "DocNum" ||
        key == "RouteStage" ||
        key == "Employee" ||
        key == "Segment" ||
        key == "Task" ||
        key == "IssueID" ||
        key == "Date"
      ) {
        headers.push({
          title: key == "Segment" ? "Working Location" : key,
          dataIndex: key,
          filters: object[key],
          filterSearch: true,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    var array = defaultFilters[0];
    var object = defaultFilters[0];

    Object.keys(object).forEach((key) => {
      rows.forEach((element) => {
        if (element[key] && !object[key].some((x) => x.value == element[key])) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  function createMainActionButtons(issue) {
    return (
      <div className="row" key={issue.id}>
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to={{
              pathname: `/activity-add-edit`,
              state: {
                params: {
                  docStgId: issue.docStgId, //1
                  issueId: issue.id,
                  pEntry: issue.projectEntry,
                  docEntry: issue.docEntry,
                  stgEntry: issue.stgEntry,
                  projectName: issue.projectName,
                  docNum: issue.docNum,
                  stgDesc: issue.stgDesc,
                  userId: issue.userId,
                  stageName: issue.stageName,
                  workingLocation: issue.workingLocation,
                  subWorkingLocation: issue.subWorkingLocation,
                  problemLocation: issue.problemLocation,
                  treatmentPoint: issue.treatmentPoint,
                  action: "update",
                },
              },
            }}
            className="btn btn-warning mr-2"
          >
            <FaPen />
          </Link>
          <button onClick={() => openDialog(issue.id)} className="btn btn-info">
            <FaCheck />
          </button>
        </div>
      </div>
    );
  }

  function createModel(issue, issueItem, issueResource, type, actionNum, flag) {
    let source;
    switch (type) {
      case "issue":
        source = {
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeFullName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issue.startDate).format("DD/MM/YYYY"),
          StartHour: moment(issue.startDate).format("HH:mm"),
          EndHour: moment(issue.endDate).format("HH:mm"),
          Type: "Labor",
          Resource: "Labor",
          ConsumptionAmount: timeDiff(issue.startDate, issue.endDate),
          ConsumtionUnit: "hour",
          SerialNumber: "-",
          Actions: flag && actionNum == 0 ? createMainActionButtons(issue) : "",
        };
        break;

      case "issueResource":
        source = {
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeFullName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issueResource.startDate).format("DD/MM/YYYY"),
          StartHour: moment(issueResource.startDate).format("HH:mm"),
          EndHour: moment(issueResource.endDate).format("HH:mm"),
          Type: "Equipment",
          Resource: issueResource.resourceName,
          ConsumptionAmount:
            parseInt(
              moment
                .duration(
                  moment(issueResource.endDate).diff(issueResource.startDate)
                )
                .asHours()
            ) +
            "H " +
            (parseInt(
              moment
                .duration(
                  moment(issueResource.endDate).diff(issueResource.startDate)
                )
                .asMinutes()
            ) %
              60) +
            "M",
          ConsumtionUnit: "hour",
          SerialNumber: issueResource.resourceSerial,
          Actions: flag && actionNum == 2 ? createMainActionButtons(issue) : "",
        };
        break;

      case "issueItem":
        source = {
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeFullName,
          Segment: issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issue.startDate).format("DD/MM/YYYY"),
          StartHour: "-",
          EndHour: "-",
          Type: "Materials",
          Resource: issueItem.itemName,
          ConsumptionAmount: formatDecimal(issueItem.quantity) ,
          ConsumtionUnit: issueItem.itemInventoryUoM,
          SerialNumber: "-",
          Actions: flag && actionNum == 1 ? createMainActionButtons(issue) : "",
        };
        break;

      case "mainAction":
        source = {
          ProjectName: "",
          MainWork: "",
          DocNum: "",
          RouteStage: "",
          Employee: "",
          Segment: "",
          Task: "",
          IssueID: "",
          Date: "",
          StartHour: "",
          EndHour: "",
          Type: "",
          Resource: "",
          ConsumptionAmount: "",
          ConsumtionUnit: "",
          SerialNumber: "",
          Actions: "",
        };
        break;

      default:
        break;
    }
    return source;
  }
  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <FilterContext.Provider value={contextData}>
      <div className="assign-activities row mt-5">
        {/* <div className="col-12 mt-5 mb-5">
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Filter</Typography>
              </ExpansionPanelSummary>
              <Card>
                <CardContent>
                  <Filters />
                </CardContent>
              </Card>
            </ExpansionPanel>
          </div> */}
        <div className="col-12">
          <Table
            columns={columns}
            dataSource={rows}
            onChange={onChange}
            scroll={{ x: 3000 }}
            loading={loading}
          />
        </div>
        <UserDialog
          open={openPopup}
          title={popupTitle}
          body={popupBody}
          approveAction={approveAction}
          rejectAction={rejectAction}
        ></UserDialog>
        <UserSnackbars
          open={openSnackbar}
          variant={variantSnackbar}
          message={messageSnackbar}
          timeout={timeout}
          handleClose={closeSnackbar}
        ></UserSnackbars>
        {/* <DataTable
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={changePage}
            setRowsPerPage={setrowsPerPageNumber}
            rows={rows}
          ></DataTable> */}
      </div>
    </FilterContext.Provider>
  );
}

export default ApproveWaitingActivities;
